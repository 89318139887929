<template>
<v-form ref="form" lazy-validation @submit.prevent="submitForm">
    <v-row>
        <v-col cols="12" class="p-0">
            <v-text-field :label="$t('firstName')" v-model="formValues.first_name" color="#59D79D" :rules="firstNameRules" :disabled="isLoading"></v-text-field>
        </v-col>
        <v-col cols="12" class="p-0">
            <v-text-field :label="$t('lastName')" v-model="formValues.last_name" color="#59D79D" :rules="lastNameRules" :disabled="isLoading"></v-text-field>
        </v-col>
        <v-col cols="12" class="p-0">
            <v-text-field :label="$t('description')" v-model="formValues.description" color="#59D79D" :disabled="isLoading"></v-text-field>
        </v-col>
        <v-col cols="12" class="p-0">
            <v-text-field :label="$t('email')" v-model="formValues.email" color="#59D79D" :disabled="!!this.user || isLoading" :rules="emailRules"></v-text-field>
        </v-col>
        <v-col cols="4" class="py-0 pl-0">
            <v-autocomplete :items="identification_types" :label="$t('identificationType')" item-text="value" item-value="value" v-model="formValues.identification_type" color="#59D79D" :disabled="isLoading">
                <template v-slot:item="data">
                    <v-list-item-content>
                        <v-list-item-subtitle>
                            <flag :iso="data.item.country" />
                            <span class="pl-2"> {{ data.item.value }}</span>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </template>
            </v-autocomplete>
        </v-col>
        <v-col cols="8" class="p-0">
            <v-text-field :label="$t('identificationCard')" color="#59D79D" v-model="formValues.identification_card" type="number" :disabled="isLoading"></v-text-field>
        </v-col>
        <v-col cols="12" class="p-0">
            <v-text-field :label="$t('userName')" v-model="formValues.username" color="#59D79D" :rules="userNameRules" :disabled="isLoading"></v-text-field>
        </v-col>
        <v-col cols="12" class="p-0" v-if="formValues.type_user >= proskerTypeId">
            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="dateFormatted" :label="$t('birthDate')" readonly prepend-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker
                    v-model="formValues.birth_date"
                    no-title
                    @input="menu = false"
                    :locale="($i18n.locale.toLowerCase().includes('br')) ?
                        'pt' :
                        $i18n.locale"
                    show-adjacent-months
                />
            </v-menu>
        </v-col>
        <v-col cols="4" class="py-0 pl-0">
            <v-autocomplete :items="phoneCodes" :label="$t('countryCode')" prefix="+" item-text="code" item-value="code" return-object v-model="formValues.phoneInternationalInfos" color="#59D79D" :disabled="isLoading">
                <template v-slot:item="data">
                    <v-list-item-content>
                        <v-list-item-subtitle>
                            <flag :iso="data.item.country" />
                            <span class="pl-2"> {{ data.item.code }}</span>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </template>
            </v-autocomplete>
        </v-col>
        <v-col cols="8" class="p-0">
            <v-text-field :label="$t('phoneNumber')" :counter="formValues.phoneInternationalInfos.phoneMask.length" :maxlength="formValues.phoneInternationalInfos.phoneMask.length" color="#59D79D" v-model="formValues.phone_number" v-mask="formValues.phoneInternationalInfos.phoneMask" :rules="getPhoneRules" :disabled="isLoading"></v-text-field>
        </v-col>
        <v-col cols="12" class="p-0">
            <v-text-field :label="$t('password')" required color="#59D79D" type="password" v-model="password" v-if="!this.user " :disabled="isLoading"></v-text-field>
        </v-col>
        <v-col cols="12" class="p-0">
            <v-text-field :label="$t('confirmPassword')" required color="#59D79D" type="password" v-model="confirmPassword" v-if="!this.user " :disabled="isLoading"></v-text-field>
        </v-col>
        <v-col cols="12" class="p-0">
            <v-autocomplete :label="$t('typeUser')" :items="usersType" :search-input.sync="searchInput" @change="searchInput = ''" v-model="formValues.type_user" disabled item-text="name" item-value="user_type" color="#59D79D" item-color="#59D79D"></v-autocomplete>
        </v-col>
        <v-col cols="12" class="p-0">
            <v-switch
              v-model="formValues.is_test_user"
              :label="$t('isTestUser')"
              :disabled="isLoading"
            />
        </v-col>
    </v-row>
    <v-spacer></v-spacer>
    <slot name="actionArea" :submitForm="submitForm" :resetForm="resetForm"></slot>
    <v-row>
        <v-col col="12" class="d-flex justify-end">
            <save-button :save="submitForm" :loading="isLoading" :message="$t('save')" v-show="!hasActionSlot()" />
        </v-col>
    </v-row>
</v-form>
</template>

<script>
import {
    getIdentificationTypes,
    getPhoneCodes,
    removeMaskFromPhoneNumber
} from '@/misc/utils';
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';
import {
    USER_TYPE_PROSKER
} from '@/misc/constants';
import {
    PhoneNumberUtil
} from 'google-libphonenumber';
import {
    mask
} from 'vue-the-mask'
import moment from 'moment';
const phoneCodes = getPhoneCodes();
const defaultPhoneInternationalInfos = phoneCodes[1];

export default {
    name: 'AddEditUserForm',
    props: ['user', 'onSubmit', 'onSubmitSuccess', 'isLoading'],
    directives: {
        mask
    },
    components: {
        SaveButton
    },
    data () {
        return {
            identification_types: getIdentificationTypes(),
            proskerTypeId: USER_TYPE_PROSKER,
            dateFormatted: null,
            phoneCodes: phoneCodes,
            searchInput: '',
            menu: false,
            formValues: {
                first_name: null,
                last_name: null,
                description: null,
                email: null,
                identification_type: null,
                identification_card: null,
                username: null,
                type_user: null,
                birth_date: null,
                phoneInternationalInfos: defaultPhoneInternationalInfos,
                phone_number: null,
                password: null,
                confirmPassword: null,
                is_test_user: null
            },
            usersType: [{
                    id: 0,
                    user_type: 0,
                    name: 'Cliente'
                },
                {
                    id: 1,
                    user_type: 10,
                    name: 'Prosker'
                },
                {
                    id: 2,
                    user_type: 20,
                    name: 'Admin'
                }
            ],
            firstNameRules: [
                v => !!v || this.$t('fieldNameRequired')
            ],
            lastNameRules: [
                v => !!v || this.$t('fieldLastNameRequired')
            ],
            phoneRules: [
                v => !!v || 'Phone is required',
                v => this.validatePhone(v, this.formValues.phoneInternationalInfos) || 'Invalid phone number'
            ],
            emailRules: [
                v => !!v || this.$t('fieldEmailRequired')
            ],
            userNameRules: [
                v => !!v || this.$t('fieldUserRequired')
            ]
        };
    },
    computed: {
        getPhoneRules () {
            return [
                v => !!v || 'Phone is required',
                v => this.validatePhone(v, this.formValues.phoneInternationalInfos) || 'Invalid phone number'
            ]
        }
    },
    methods: {
        async submitForm () {
            if (this.isLoading) {
                return;
            }
            if (this.$refs.form.validate()) {
                const phoneWithoutMask = removeMaskFromPhoneNumber(this.formValues.phone_number);
                const phoneCode = this.formValues.phoneInternationalInfos.code;
                const originalDate = new Date(this.formValues.birth_date);
                const formattedDate = moment(moment.utc(originalDate)).format(
                    'DD-MM-YYYY'
                );
                if (this.user) {
                    delete this.formValues.password;
                    delete this.formValues.confirmPassword;
                }
                this.onSubmit({
                    ...this.formValues,
                    phone_number: phoneWithoutMask,
                    phone_code: phoneCode,
                    birth_date: formattedDate
                });
            }
        },
        formatDate (date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            if (this.$i18n.locale.toLowerCase().includes('en')) {
                return `${month}/${day}/${year}`
            } else {
                return `${day}/${month}/${year}`
            }
        },
        validatePhone (phone, phoneInternationalInfos) {
            const phoneUtil = PhoneNumberUtil.getInstance();
            const phoneValue = !!phone === false || phone.length < 3 ? '123' : phone; // put this 123 to avoid error when phone is null and min size but still invalid
            return phoneUtil.isValidNumberForRegion(phoneUtil.parse(removeMaskFromPhoneNumber(phoneValue), phoneInternationalInfos.country), phoneInternationalInfos.country)
        },
        resetForm () {
            this.$refs.form.reset();
        },
        hasActionSlot () {
            return !!this.$scopedSlots.actionArea
        },
        prePropulateForm () {
            if (this.user) {
                this.formValues.first_name = this.user.first_name;
                this.formValues.last_name = this.user.last_name;
                this.formValues.description = this.user.description;
                this.formValues.email = this.user.email;
                this.formValues.identification_type = this.user.identification_type;
                this.formValues.identification_card = this.user.identification_card;
                this.formValues.username = this.user.username;
                this.formValues.type_user = this.user.type_user;
                this.formValues.is_test_user = this.user.is_test_user;
                const originalDate = new Date(this.formValues.birth_date);
                const formattedDate = moment(moment.utc(originalDate)).format(
                    'YYYY-MM-DD'
                );
                this.formValues.birth_date = formattedDate;

                if (this.user.phone_code) {
                    const foundedInternationalInfos = phoneCodes.find((phoneCode) => phoneCode.code === parseInt(this.user.phone_code));
                    this.formValues.phoneInternationalInfos = foundedInternationalInfos || defaultPhoneInternationalInfos;
                } else {
                    this.formValues.phoneInternationalInfos = defaultPhoneInternationalInfos;
                }
                this.formValues.phone_number = this.user.phone_number;
            }
        }
    },
    watch: {
        'formValues.birth_date' (val) {
            this.dateFormatted = this.formatDate(this.formValues.birth_date);
        },
        '$i18n.locale': function (newVal, oldVal) {
            if (newVal) {
                this.dateFormatted = this.formatDate(this.formValues.birth_date);
            }
        },
        user: {
            handler: function (val) {
                if (val) {
                    this.prePropulateForm();
                }
            },
            deep: true
        }
    }
}
</script>
